<template>
  <div class="register-select app_content">
    <div class="warp_body">
      <div v-html="value" @click="showPreview($event)">

      </div>
      <van-image-preview v-model:show="show"
                         :images="['https://www.bxthr.com/storage/images/company/vipExplain.png']"></van-image-preview>
    </div>


  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import API from "../api/User";
import req from "../request/request";
import {useRoute} from "vue-router";
import {ImagePreview, Toast} from "vant";


export default {
  name: 'VipProtocol',

  setup() {
    onBeforeMount(() => {
      const route = useRoute();
      methodsMap.getVal(route.name)
    });
    const data = reactive({
          value: '',
          show: false
        },
    )

    const methodsMap = {
      getVal: (name) => {
        req.request(API.getRouteContent, {name: name}, {method: 'GET'}).then((res) => {
          data.value = res.data.data.value
          console.log(data.value)
        })
      },
      showPreview: (e) => {
        console.log(e)
        if (e.target.nodeName == 'IMG')
          ImagePreview({
            images: [e.target.currentSrc],
            showIndex:false,
            closeable:true,
          });
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {

    .registerSelect {
      width: 90%;
      margin: 40px auto;
    }

    .protocol {
      text-align: center;
      font-size: 10px;
      color: blue;
    }
  }
}
</style>
